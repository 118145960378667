import myImg from '../assets/myImg.png';
import heroImg from '../assets/hero.png';

export const datamain=[
    {
        id:1,
        mainTitle:"Full-Stack Developer & Mentor",
        secondTitle:"A full-stack developer who likes to design.",
        myImg:myImg,
        heroImg:heroImg,
        
    },
    
]