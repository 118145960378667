import helloIcon from '../assets/hello.png';
export const startmentoring=[
    {
        id:1,
        title:"Book a consult",
        content:"Thinking about mentorship? Let's talk about it. The first call is on me.",
        btnIcon:helloIcon,
        btnText:"Introduce yourself",
        
        
    },
    
]