import okIcon from '../assets/ok.png';
export const startproject=[
    {
        id:1,
        title:"Start a Project",
        content:"Interested in working together?We should queue up a time to chat.I'll buy the coffee.",
        btnIcon:okIcon,
        btnText:"Let's do this",
        
        
    },
    
]