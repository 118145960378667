function About({title, content}) {
  return (
    <div class="about-me">
        <div className="content">
            <h3>{title}</h3>
            <p>{content} </p>
            
        </div>
      
    </div>
  );
}
export default About;