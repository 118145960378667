
import heroImg from '../assets/mentoringhero.png';

export const datamentor=[
    {
        id:1,
        mainTitle:"I haven't met you, and this is crazy...",
        secondTitle:"But if you need a mentor, then email me maybe...",
        myImg:'',
        heroImg:heroImg,
        
    },
    
]