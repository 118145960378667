import ContactForm from "../components/ContactForm";
import FormHeader from "../components/FormHeader";
function ContactPage() {
  return (
    <>
     <FormHeader />
     <ContactForm />
     
    </>
  );
}
export default ContactPage;