import React from 'react';
import { data } from "../data/data.js";
import PortfolioCard from '../components/PortfolioCard.jsx';    



const Portfolio = () => {
    const project = data;
  
  return (
    <div className='portfolio-container ' >
     
          <p>Portfolio</p>
   <div className="projects-container">
          {project.map((item, index) => (
            <PortfolioCard
              key={index}
              image={item.image}
              name={item.name}
              github={item.github}
              live={item.live}
              bcolor={item.bcolor}
              logo={item.logo}
             
            />     
            ))}
    </div>
</div>

  );
};

export default Portfolio;
