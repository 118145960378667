import logo from '../assets/logo.png'
import {
  FaBars,
  FaTimes,
  FaGithub,
  FaLinkedin,
  FaFacebook,
  FaLinkedinIn,
} from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
const Footer=()=>{
    return(
        <div className="footer-container">
            <div className="logo-container">
                <img className="footer-logo" src={logo} alt="logo"/>

            </div>
            
            <div >
                 <ul className="footer-social">
          
          <li className='social-link'>
            
            <a
             
              href='https://www.linkedin.com/in/aysun-itai-31390b202/'
            >
              
              <FaLinkedin  size={30} />
            </a>
          </li>
          <li className='social-link'>
            <a
              
              href='https://github.com/aysunitai'
            >
               <FaGithub size={30} />
            </a>
          </li>
          <li className='social-link'>
            <a
              
              href='mailto:aysun.itai@gmail.com'
            >
              <HiOutlineMail size={30} />
            </a>
          </li>
          
        </ul>

            </div>
            <h4 className='footer-heading'>Copyright@2024AysunItai</h4>
        </div>
    )

}

export default Footer;