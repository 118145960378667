import myjsmaster from '../assets/projects/myjsmaster.png'
import myrecipes from '../assets/projects/myrecipes.png'
import trainers from '../assets/projects/trainers.png'
import logojs from '../assets/myjsmasterlogo.svg'
import fflogo from '../assets/fflogo.png'
import crossfit from '../assets/crossfit.png'
import ilogo from '../assets/ilogo.png'
import omSkin from '../assets/projects/omskin.png'
import invoice from '../assets/projects/invoice.png'
import omSkinLogo from '../assets/omskinlogo.png'
import bookLogo from '../assets/bookingl.png'
import linkbagpic from '../assets/projects/linkbag.png'
import linkbaglogo from '../assets/linkbaglogo.png'
import bookLanding from '../assets/projects/booklanding.png'

export const data=[
    {
        id:1,
        name:"This is a vanilla JS app, with a custom design, and SASS",
        image:omSkin,
        github:"https://github.com/AysunItai/myjsmaster",
        live:"https://omskin.web.app/",
        bcolor:"#f5c1c5",
        logo:omSkinLogo
    },
    {
        id:2,
        name:"A full stack app with React, Context Api, Firebase, Tailwind CSS",
        image:myrecipes,
        github:"https://github.com/AysunItai/mybestrecipes",
        live:"https://recipes-7ec97.web.app/",
        bcolor:"green",
        logo:fflogo
    },
    {
        id:3,
        name:"A Multipage ReactJS  app , full page video background with CSS3.",
        image:trainers,
        github:"https://github.com/AysunItai/trainerWebSite.git",
        live:"https://dynamic-lolly-4f48e8.netlify.app/",
        bcolor:"#111",
        logo:crossfit
    },
    {
        id:4,
        name:"A Single Page Bootstrap  app , Book Landing Page.",
        image:bookLanding,
        github:"https://github.com/AysunItai/trainerWebSite.git",
        live:"https://booklanding-2324f.web.app/",
        bcolor:"#af2007",
        logo:bookLogo
    },
    {
        id:5,
        name:"An invoice generator with React",
        image:invoice,
        github:"https://github.com/AysunItai/myjsmaster",
        live:"https://invoice-app-ba991.web.app/",
        bcolor:"#0274e1",
        logo:ilogo
        
    },
    {
        id:6,
        name:"A safe space to save all your links!",
        image:linkbagpic,
        github:"https://github.com/AysunItai/myjsmaster",
        live:"https://linkbag-6c5a4.web.app/",
        bcolor:"#C1BA6D",
        logo:linkbaglogo
        
    },



]