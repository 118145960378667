import ProjectForm from "../components/ProjectForm"
import FormHeader from "../components/FormHeader"


function ProjectFormPage(){
    return (
        <>
            <FormHeader />
            <ProjectForm />
        </>
    )
}
export default ProjectFormPage;