

export const dataaboutme=[
    {
        id:1,
        title:"Hi, I’m Aysun. Nice to meet you.",
        content:"Since beginning my journey as a freelance developer over 6 years ago , I have done remote work for agencies, and collaborated with talented people to create digital products for both business and consumer use.",
        
        
    },
    
]