import mentoring from "../assets/mentoring.png";
import fullstack from "../assets/fullstack.png";


function Skills() {
  return (
    <div className="skills">
      <div className="container">
        <div className="fullstack">
            <img src={fullstack} alt="fullstack" />
            <h1>Full Stack Developer</h1>
            <p>I like to code things from scratch, and enjoy bringing ideas to life in the browser.</p>
            <div className="skills-body">
            
                <h2>Languages I speak:</h2>
                <p>HTML,CSS,JavaScript,PHP</p>
            
            
                <h2>Frameworks && DBs</h2>

                  
                <p>React,Node,Express</p>
                <p>MySQL,MongoDb</p>
            
           <div>
                <h2>Tools I use:</h2>
                <ul>
                    <li>Git</li>
                    <li>GitHub</li>
                    <li>VS Code</li>
                    <li>Postman</li>
                    <li>Heroku</li>
                    <li>Netlify</li>
                </ul>
            </div>  
            </div>
        </div>
        <div className="mentor">
            <img src={mentoring} alt="mentoring" />
            <h1>Mentor</h1>
              <p>I genuinely care about people, and love helping fellow developers work on their craft.</p>
            <div className="skills-body">
               
                <h2>What I offer:</h2>
                <p>HTML, CSS, JavaScript, React, ...</p>

            
                <h2>Mentor Stats:</h2>
               <ul>
                     <li><span>70+</span> Mentees</li>
                     <li><span>5/5</span>Rating</li>
                     <li><span>1200+</span> mentor sessions</li>
                     <li><span>40+</span> rewiews</li>
               </ul>
            </div>
            
          </div>
      </div>
    </div>
  );
}   
export default Skills;