

export const datamentorabout=[
    {
        id:1,
        title:"Becoming a programmer can be quite challenging!",
        content:"With the ever-evolving landscape of technology, it feels like there's always something new to grasp, a fresh framework to familiarize yourself with, and a never-ending stream of documentation and tutorials to go through. I understand this firsthand, having traversed this path myself.Whether you're just starting out in the world of coding or seeking some valuable direction and encouragement, I'm here to lend a hand.",
        
        
    },
    
]