function PortfolioCard({image, name, github, live, index,bcolor,logo}){
    return(
    <div
    key={index}
    className="project-card" 
    >
        <img src={image} alt="project"  />
    <div className="hover-content">

      
       
       
        <a href={github} class="animated-button2" style={{width:'50px', height:'40px'}} >
        Code
        </a>
        
        <a href={live} class="animated-button2" style={{width:'50px', height:'40px'}} >
            Live
        </a>
      
    </div>
    <div className="overlay" style={{backgroundColor:bcolor}}><p className="projectDescription">{name}</p>
      <img src={logo} alt="logo" />
      </div>
  </div>
    )

}
export default PortfolioCard;